import { gql } from '@apollo/client'

const EDI_FRAGMENT = gql`
  fragment EdiFragment on edi_template {
    id
    name
    company
    client_id
    created_at
    path_name
    server_host
    server_port
    server_username
    server_password
    daily_inventory_message
    client {
      id
      name
    }
    template
    is_enable
  }
`

const GET_EDI_TEMPLATES = gql`
  query ediTemplates(
    $limit: Int
    $offset: Int
    $orderBy: [edi_template_order_by!]
    $where: edi_template_bool_exp
  ) {
    templatesAggregate: edi_template_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    templates: edi_template(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      ...EdiFragment
    }
  }
  ${EDI_FRAGMENT}
`

const GET_EDI_TEMPLATE = gql`
  query ediTemplate($id: Int!) {
    template: edi_template_by_pk(id: $id) {
      ...EdiFragment
    }
  }
  ${EDI_FRAGMENT}
`

const GET_MAERSK_FORM_DATA = gql`
  query maerskFormData {
    clients: organization(
      where: {
        deleted_at: { _is_null: true }
        organization_organization_types: {
          organization_type: { name: { _eq: "CLIENT" } }
        }
      }
      order_by: { name: asc }
    ) {
      id
      name
    }
    locations: location(
      where: { deleted_at: { _is_null: true } }
      order_by: { name: asc }
    ) {
      id
      name
    }
    liner_codes: edi_code(
      where: { company: { _eq: MAERSK }, type: { _eq: INTERCHANGE } }
      order_by: { name: asc }
    ) {
      id
      code
      name
      type
    }
    seal_codes: edi_code(
      where: { company: { _eq: MAERSK }, type: { _eq: SEAL_PARTY } }
      order_by: { name: asc }
    ) {
      id
      code
      name
      type
    }
    seal_types: seal_type {
      comment
      value
    }
    liners: organization(
      where: {
        deleted_at: { _is_null: true }
        organization_organization_types: {
          organization_type: { name: { _eq: "LINER" } }
        }
      }
      order_by: { name: asc }
    ) {
      id
      name
    }
  }
`

const GET_KING_OCEAN_FORM_DATA = gql`
  query kingOceanFormData {
    clients: organization(
      where: {
        deleted_at: { _is_null: true }
        organization_organization_types: {
          organization_type: { name: { _eq: "CLIENT" } }
        }
      }
      order_by: { name: asc }
    ) {
      id
      name
    }
    locations: location(
      where: { deleted_at: { _is_null: true } }
      order_by: { name: asc }
    ) {
      id
      name
    }
  }
`

const GET_CODECO_FORM_DATA = gql`
  query codecoFormData {
    clients: organization(
      where: {
        deleted_at: { _is_null: true }
        organization_organization_types: {
          organization_type: { name: { _eq: "CLIENT" } }
        }
      }
      order_by: { name: asc }
    ) {
      id
      name
    }
    locations: location(
      where: { deleted_at: { _is_null: true } }
      order_by: { name: asc }
    ) {
      id
      name
    }
    seal_codes: edi_code(
      where: { company: { _eq: CODECO }, type: { _eq: SEAL_PARTY } }
      order_by: { name: asc }
    ) {
      id
      code
      name
      type
    }
    seal_types: seal_type {
      comment
      value
    }
    liners: organization(
      where: {
        deleted_at: { _is_null: true }
        organization_organization_types: {
          organization_type: { name: { _eq: "LINER" } }
        }
      }
      order_by: { name: asc }
    ) {
      id
      name
    }
  }
`

const CREATE_EDI_TEMPLATE = gql`
  mutation insertEdiTemplate($object: edi_template_insert_input!) {
    template: insert_edi_template_one(object: $object) {
      ...EdiFragment
    }
  }
  ${EDI_FRAGMENT}
`

const UPDATE_EDI_TEMPLATE = gql`
  mutation updateEdiTemplate(
    $id: Int!
    $name: String
    $path_name: String
    $server_host: String
    $server_port: Int
    $server_username: String
    $server_password: String
    $daily_inventory_message: Boolean
    $client_id: Int!
    $template: jsonb!
    $is_enable: Boolean!
  ) {
    template: update_edi_template_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        path_name: $path_name
        server_host: $server_host
        server_port: $server_port
        server_username: $server_username
        server_password: $server_password
        daily_inventory_message: $daily_inventory_message
        client_id: $client_id
        template: $template
        is_enable: $is_enable
      }
    ) {
      ...EdiFragment
    }
  }
  ${EDI_FRAGMENT}
`

const DELETE_EDI_TEMPLATE = gql`
  mutation deleteEdiTemplate($id: Int!) {
    template: update_edi_template_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: "now()" }
    ) {
      id
    }
  }
`
const RETRY_EDI_MESSAGE = gql`
  mutation retryEDI($transaction: retry_edi_message_input!) {
    retry_edi_message(transaction: $transaction) {
      message
    }
  }
`

export default {
  GET_EDI_TEMPLATES,
  GET_EDI_TEMPLATE,
  GET_MAERSK_FORM_DATA,
  GET_KING_OCEAN_FORM_DATA,
  GET_CODECO_FORM_DATA,
  CREATE_EDI_TEMPLATE,
  UPDATE_EDI_TEMPLATE,
  DELETE_EDI_TEMPLATE,
  RETRY_EDI_MESSAGE
}
