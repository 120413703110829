import { constants } from 'config'

const {
  ENUM_DATA: {
    EDI_COMPANIES: { UNIVERSAL }
  }
} = constants

const createFormat = ({
  name,
  path_name,
  server_host,
  server_port,
  server_username,
  server_password,
  daily_inventory_message,
  client_id,
  client_code_id,
  locations,
  liner_codes,
  seal_codes,
  templates
}) => {
  return {
    variables: {
      object: {
        name,
        path_name,
        server_host,
        server_port,
        server_username,
        server_password,
        daily_inventory_message,
        client_id,
        company: UNIVERSAL,
        template: {
          client_code_id,
          locations,
          liner_codes,
          seal_codes,
          templates
        }
      }
    }
  }
}

const formatFormValues = (
  initialValues,
  {
    id,
    name,
    path_name,
    is_enable,
    client_id,
    server_host,
    server_port,
    server_username,
    server_password,
    daily_inventory_message,
    template: { client_code_id, locations, liner_codes, seal_codes, templates }
  }
) => {
  return {
    ...initialValues,
    id,
    name,
    path_name,
    is_enable,
    server_host,
    server_port,
    server_username,
    server_password,
    daily_inventory_message,
    client_id,
    client_code_id,
    locations,
    liner_codes,
    seal_codes,
    templates
  }
}

const updateFormat = ({
  id,
  name,
  path_name,
  is_enable,
  server_host,
  server_port,
  server_username,
  server_password,
  daily_inventory_message,
  client_id,
  client_code_id,
  locations,
  liner_codes,
  seal_codes,
  templates
}) => {
  return {
    variables: {
      id,
      name,
      path_name,
      is_enable,
      server_host,
      server_port: server_port ? Number(server_port) : null,
      server_username,
      server_password,
      daily_inventory_message,
      client_id,
      template: {
        client_code_id,
        locations,
        liner_codes,
        seal_codes,
        templates
      }
    }
  }
}

const deleteFormat = id => {
  return {
    variables: {
      id
    }
  }
}

export const dynamicData = [
  {
    label: 'TEMPLATE_LOCATION_PORT_NAME',
    key: 'TEMPLATE_LOCATION_PORT_NAME'
  },
  {
    label: 'TEMPLATE_LOCATION_ID',
    key: 'TEMPLATE_LOCATION_ID'
  },
  {
    label: 'TEMPLATE_SEAL_CODE',
    key: 'TEMPLATE_SEAL_CODE'
  },
  {
    label: 'TEMPLATE_LINER_CODE',
    key: 'TEMPLATE_LINER_CODE'
  },
  {
    label: 'TEMPLATE_CLIENT_CODE',
    key: 'TEMPLATE_CLIENT_CODE'
  },
  {
    label: 'RANDOM_ID',
    key: 'RANDOM_ID'
  },
  {
    label: 'ID',
    key: 'ID'
  },
  {
    label: 'CONTAINER_STATUS',
    key: 'CONTAINER_STATUS'
  },
  {
    label: 'CONTAINER_CONDITION',
    key: 'CONTAINER_CONDITION'
  },
  {
    label: 'CARGO_WEIGHT_KG',
    key: 'CARGO_WEIGHT_KG'
  },
  {
    label: 'IS_DAMAGE',
    key: 'IS_DAMAGE'
  },
  {
    label: 'BOOKING_NUMBER',
    key: 'BOOKING_NUMBER'
  },
  {
    label: 'STACK',
    key: 'STACK'
  },
  {
    label: 'LATLONG',
    key: 'LATLONG'
  },
  {
    label: 'UUID',
    key: 'UUID'
  },
  {
    label: 'CREATED_AT',
    key: 'CREATED_AT'
  },
  {
    label: 'TYPE',
    key: 'TYPE'
  },
  {
    label: 'DRIVER_NAME',
    key: 'DRIVER_NAME'
  },
  {
    label: 'DRIVER_ID',
    key: 'DRIVER_ID'
  },
  {
    label: 'PLATE_ID',
    key: 'PLATE_ID'
  },
  {
    label: 'TRANSPORTATION_TYPE',
    key: 'TRANSPORTATION_TYPE'
  },
  {
    label: 'VERSION',
    key: 'VERSION'
  },
  {
    label: 'TRANSACTION_GATE_IN_ID',
    key: 'TRANSACTION_GATE_IN_ID'
  },
  {
    label: 'UNIQUE_UUID',
    key: 'UNIQUE_UUID'
  },
  {
    label: 'PTI_PASS',
    key: 'PTI_PASS'
  },
  {
    label: 'CLEAN',
    key: 'CLEAN'
  },
  {
    label: 'SET_POINT_CELSIUS',
    key: 'SET_POINT_CELSIUS'
  },
  {
    label: 'RETURN_TEMPERATURE',
    key: 'RETURN_TEMPERATURE'
  },
  {
    label: 'SUPPLY_TEMPERATURE',
    key: 'SUPPLY_TEMPERATURE'
  },
  {
    label: 'HUMIDITY_SET_POINT',
    key: 'HUMIDITY_SET_POINT'
  },
  {
    label: 'HUMIDITY_ACTUAL',
    key: 'HUMIDITY_ACTUAL'
  },
  {
    label: 'VENTILATION',
    key: 'VENTILATION'
  },
  {
    label: 'CONSECUTIVE_FOLIO_NUMBER',
    key: 'CONSECUTIVE_FOLIO_NUMBER'
  },
  {
    label: 'CONTAINER_NUMBER',
    key: 'CONTAINER_NUMBER'
  },
  {
    label: 'CONTAINER_ISO_TYPE',
    key: 'CONTAINER_ISO_TYPE'
  },
  {
    label: 'MGW_KG',
    key: 'MGW_KG'
  },
  {
    label: 'TARE_KG',
    key: 'TARE_KG'
  },
  {
    label: 'MAX_PAYLOAD_KG',
    key: 'MAX_PAYLOAD_KG'
  },
  {
    label: 'VOLUME_CBM',
    key: 'VOLUME_CBM'
  },
  {
    label: 'CONTAINER_YEAR',
    key: 'CONTAINER_YEAR'
  },
  {
    label: 'RELEASE_ID',
    key: 'RELEASE_ID'
  },
  {
    label: 'RELEASE_UN_LOCODE',
    key: 'RELEASE_UN_LOCODE'
  },
  {
    label: 'RELEASE_TO_LOCATION_CODE',
    key: 'RELEASE_TO_LOCATION_CODE'
  },
  {
    label: 'RELEASE_NAME',
    key: 'RELEASE_NAME'
  },
  {
    label: 'INVENTORY',
    key: 'INVENTORY'
  }
]

export default {
  createFormat,
  formatFormValues,
  updateFormat,
  deleteFormat,
  dynamicData
}
