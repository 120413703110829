import { gql } from '@apollo/client'

const EDI_MESSAGE_FRAGMENT = gql`
  fragment EdiMessageFragment on edi_message {
    id
    transaction_id
    template_id
    message
    status
    created_at
  }
`

const GET_EDI_MESSAGES = gql`
  query ediMessages(
    $limit: Int
    $offset: Int
    $orderBy: [edi_message_order_by!]
    $where: edi_message_bool_exp
  ) {
    messagesAggregate: edi_message_aggregate(where: $where) {
      aggregate {
        totalCount: count
      }
    }
    messages: edi_message(
      where: $where
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      ...EdiMessageFragment
    }
  }
  ${EDI_MESSAGE_FRAGMENT}
`

const GET_EDI_MESSAGE = gql`
  query ediMessage($id: Int!) {
    message: edi_message_by_pk(id: $id) {
      ...EdiMessageFragment
    }
  }
  ${EDI_MESSAGE_FRAGMENT}
`

export default {
  GET_EDI_MESSAGES,
  GET_EDI_MESSAGE
}
