import * as yup from 'yup'
import { validations } from '@edenia/smartgate-errors-module'

const initialValues = {
  id: '',
  is_enable: '',
  name: '',
  path_name: '',
  client_id: '',
  client_code_id: '',
  server_host: '',
  server_port: '',
  server_username: '',
  server_password: '',
  daily_inventory_message: '',
  locations: [
    {
      location_id: '',
      location_code: '',
      port_name: ''
    }
  ],
  liner_codes: [
    {
      liner_code_id: '',
      smartgate_liner_code_id: ''
    }
  ],
  seal_codes: [
    {
      seal_code_id: '',
      smartgate_seal_code: ''
    }
  ],
  templates: [
    {
      transaction_type: '',
      template: ''
    }
  ]
}

const schema = yup.object().shape({
  id: yup.number().required(validations.requiredField.code),
  name: yup.string().required(validations.requiredField.code),
  path_name: yup.string().required(validations.requiredField.code),
  is_enable: yup.boolean().required(validations.requiredField.code),
  client_id: yup.string().required(validations.requiredField.code),
  client_code_id: yup.string().required(validations.requiredField.code),
  server_host: yup.string().nullable(),
  server_port: yup.string().nullable(),
  server_username: yup.string().nullable(),
  server_password: yup.string().nullable(),
  daily_inventory_message: yup.boolean(),
  locations: yup
    .array()
    .of(
      yup.object().shape({
        location_id: yup.string().required(validations.requiredField.code),
        location_code: yup.string().required(validations.requiredField.code),
        port_name: yup.string().required(validations.requiredField.code)
      })
    )
    .required(validations.requiredField.code),
  liner_codes: yup
    .array()
    .of(
      yup.object().shape({
        liner_code_id: yup.string().required(validations.requiredField.code),
        smartgate_liner_code_id: yup
          .string()
          .required(validations.requiredField.code)
      })
    )
    .required(validations.requiredField.code),
  seal_codes: yup
    .array()
    .of(
      yup.object().shape({
        seal_code_id: yup.string().required(validations.requiredField.code),
        smartgate_seal_code: yup
          .string()
          .required(validations.requiredField.code)
      })
    )
    .required(validations.requiredField.code),
  templates: yup
    .array()
    .of(
      yup.object().shape({
        transaction_type: yup.string().required(validations.requiredField.code),
        template: yup.string().required(validations.requiredField.code)
      })
    )
    .required(validations.requiredField.code)
})

export default {
  initialValues,
  schema
}
